import actions from "./emptyListActions";

const INITIAL_PAGE_SIZE = 10;

const types = [
  {
    value: "BOOKING",
    label: "Reserva",
  },
  {
    value: "BILL_OF_LANDING",
    label: "Guia De Carga",
  },
];

const status = [
  {
    value: "ASSIGNED",
    label: "Asignado",
  },
  {
    value: "NOT_ASSIGNED",
    label: "Sin Asignar",
  },
  {
    value: "FINISHED",
    label: "Finalizado",
  },
];

const initialState = {
  rows: [],
  count: 0,
  orderCount: 0,
  bodyCount: 0,
  loading: false,
  loadingDelegation: false,
  loadingCompanies: false,
  loadingHeader:false,
  loadingBookingForwarder:false,
  filter: {},
  filterLabel: {},
  orderFilter: {},
  pagination: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
  paginationBody: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
  orderPagination: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
  sorter: {},
  stakeholders: [],
  stakeholderByCompanyId: [],
  sorterBody: {},
  orderSorter: {},
  emptyHeader: [],
  newEmptyHeader: {},
  headerById: {},
  emptyBodyList: [],
  newBody: {},
  client: {},
  orders: [],
  types,
  status,
  booking: {},
  cuitClient: {},
  cuitClientMessage: null,
  emptyBody: {},
  editEmptyBody: {},
  bookingForwarder: {},
  attachments: [],
  bodyByIdHeader: [],
  statusHeader: [],
  bookingError: "",
  forwarderBooking: {},
  stakeholdersNoPagination: [],
  container: [],
  ordersNotPagination:[]
};

const emptyReducers = (state = initialState, { type, payload }) => {
  if (type === actions?.FETCH_ATTACHMENT_STARTED) {
    return {
      ...state,
      attachments: [],
    };
  }
  if (type === actions.RESET_OFFSET) {
    return {
      ...state,
      pagination: {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }

  

  if (type === actions.START_BOOKING_FORWARDER) {
    return {
      ...state,
      forwarderBooking: {},
      loadingDelegation: true,
    };
  }

  if (type === actions.GET_BOOKING_FORWARDER) {
    return {
      ...state,
      forwarderBooking: payload,
      loadingDelegation: false,
    };
  }
  if (type === actions.ERROR_BOOKING_FORWARDER) {
    return {
      ...state,
      loadingDelegation: false,
    };
  }

  if (type === actions.GET_STATUS) {
    return {
      ...state,
      statusHeader: payload,
    };
  }

  if (type === actions.GET_STATUS_OPTIONS) {
    return {
      ...state,
      statusHeader: payload,
    };
  }

  if (type === actions.FETCH_ATTACHMENT_SUCCESS) {
    return {
      ...state,
      attachments: payload,
    };
  }

  if (type === actions.FETCH_ATTACHMENT_ERROR) {
    return {
      ...state,
      attachments: [],
    };
  }
  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
      pagination: state.pagination,
    };
  }

  if (type === actions.FETCH_STARTED_BODY) {
    return {
      ...state,
      loading: true,
      paginationBody: state.paginationBody,
    };
  }

  if (type === actions.FETCH_STAKEHOLDER) {
    return {
      ...state,
      stakeholders: payload.list,
      total: payload.total,
    };
  }
  if (type === actions.FETCH_STAKEHOLDER_BY_COMPANY_ID) {
    return {
      ...state,
      stakeholderByCompanyId: payload.list,
      total: payload.total,
    };
  }

  if (type === actions.RESET_BOOKING_FORWARDER) {
    return {
      ...state,
      bookingForwarder: {},
      loading: true,
    };
  }

  if (type === actions.STARTED_BOOKING_FORWARDER) {
    return {
      ...state,
      bookingForwarder: {},
      loadingBookingForwarder: true,
    };
  }

  if (type === actions.BOOKING_FORWARDER) {
    return {
      ...state,
      bookingForwarder: payload.data,
      loadingBookingForwarder: false,
    };
  }
  
  if (type === actions.BOOKING_FORWARDER_ERROR) {
    return {
      ...state,
      bookingForwarder:{},
      loading: false,
    };
  }
  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      rows: payload,
      count: payload.count,
    };
  }

  if (type === actions.FETCH_SUCCESS_BODY) {
    return {
      ...state,
      loading: false,
      bodyByIdHeader: payload.list,
      bodyCount: payload.total,
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      rows: [],
      count: 0,
    };
  }

  if (type === actions.FETCH_ERROR_BODY) {
    return {
      ...state,
      loading: false,
      bodyByIdHeader: [],
      bodyCount: 0,
    };
  }

  if (type === actions.PAGINATION_CHANGED) {
    return {
      ...state,
      pagination: payload || {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }

  if (type === actions.PAGINATION_CHANGED_BODY) {
    return {
      ...state,
      paginationBody: payload || {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }

  if (type === actions.SORTER_CHANGED) {
    return {
      ...state,
      sorter: payload || {},
    };
  }

  if (type === actions.SORTER_CHANGED_BODY) {
    return {
      ...state,
      sorterBody: payload || {},
    };
  }

  if (type === actions.CHANGE_FILTER) {
    return {
      ...state,
      filter: payload,
    };
  }

  if( type === actions.CHANGE_FILTER_LABELS) {
    return {
      ...state,
      filterLabel: payload,
    };
  }

  if (type === actions.ORDER_PAGINATION_CHANGED) {
    return {
      ...state,
      orderPagination: payload || {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }

  if (type === actions.SORTER_ORDER_CHANGED) {
    return {
      ...state,
      orderSorter: payload || {},
    };
  }

  if (type === actions.CHANGE_ORDER_FILTER) {
    return {
      ...state,
      orderFilter: payload,
    };
  }

  if (type === actions.EMPTY_HEADER_SUCCESS) {
    return {
      ...state,
      loading: false,
      emptyHeader: payload.list,
      count: payload.total,
    };
  }

  if (type === actions.EMPTY_HEADER_CREATE) {
    return {
      ...state,
      newEmptyHeader: payload,
    };
  }

  if (type === actions.EMPTY_HEADER_BYID_STARTED) {
    return {
      ...state,
      loadingHeader: true,
      headerById:{}
    };
  }

  if (type === actions.EMPTY_HEADER_BYID) {
    return {
      ...state,
      loadingHeader: false,
      headerById: payload,
    };
  }

  if (type === actions.EMPTY_BODY_BY_HEADER_ID) {
    return {
      ...state,
      loading: false,
      emptyBodyList: payload,
    };
  }

  if (type === actions.EMPTY_BODY_CREATE) {
    return {
      ...state,
      loading: false,
      newBody: payload,
    };
  }

  if (type === actions.STAKEHOLDERS_NOT_PAGINATION) {
    return {
      ...state,
      stakeholdersNoPagination: payload.list,
    };
  }

  if (type === actions.ORDERS) {
    return {
      ...state,
      loading: false,
      orders: payload.list,
      count: payload.total,
    };
  }

  if (type === actions.ORDERS_NOT_PAGINATION) {
    return {
      ...state,
      orders: payload.list.filter((f) => f.status === "NOT_ASSIGNED"),
      ordersNotPagination: payload.list
    };
  }

  if (type === actions.GET_BOOKING_NAVIS) {
    return {
      ...state,
      booking: payload,
      cantContainer: [payload?.items?.item].reduce(
        (accumulator, item) => accumulator + item?.quantity,
        0
      ),
    };
  }
  if (type === actions.ERROR_GET_BOOKING_NAVIS) {
    return {
      ...state,
      bookingError: "Booking no encontrado",
    };
  }

  if (type === actions.FETCH_BOOKING_STARTED) {
    return {
      ...state,
      booking: {},
    };
  }

  if (type === actions.FETCH_ERROR_BOOKING) {
    return {
      ...state,
      booking: {},
    };
  }

  if (type === actions.GET_CUIT_SUCCESS) {
    return {
      ...state,
      cuitClient: payload,
      cuitClientMessage: payload.message,
    };
  }
  if (type === actions.GET_CUIT_ERROR) {
    return {
      ...state,
      cuitClientMessage: "Empresa no encontrada",
      cuitClient: {},
    };
  }
  if (type === actions.ADD_EMPTY_BODY) {
    return {
      ...state,
      emptyBody: payload.message,
    };
  }
  if (type === actions.EDIT_EMPTY_BODY) {
    return {
      ...state,
      editEmptyBody: payload.message,
    };
  }

  if (type === actions.GET_CONTAINER) {
    return {
      ...state,
      container: payload,
    };
  }

  return state;
};

export default emptyReducers;
