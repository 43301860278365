import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import service from '../configService';
import selectors from './ExceptionSelector';

const prefix = 'EXCEPTION_LIST';

const ExceptionActions = {
	FETCH_STARTED: `${prefix}_FETCH_STARTED`,
	FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
	FETCH_ERROR: `${prefix}_FETCH_ERROR`,

	PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,

	SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

	UPDATE_FILTER: `${prefix}_UPDATE_FILTER`,

	FETCH_SUCCES_ADDEXCEPTION: `${prefix}_FETCH_SUCCES_ADDEXCEPTION`,


	doFetch: () => async (dispatch, getState) => {
		try {
			dispatch({
				type: ExceptionActions.FETCH_STARTED,
			});

			const response = await service.getAllExceptionDays(
				selectors.selectOrderBy(getState()),
				selectors.selectLimit(getState()),
				selectors.selectOffset(getState()),
				// selectors.selectFilter(getState())
			);

			dispatch({
				type: ExceptionActions.FETCH_SUCCESS,
				payload: response,
			});
		} catch (error) {
			Errors.handle(error);

			dispatch({
				type: ExceptionActions.FETCH_ERROR,
			});
		}
	},

	doChangePagination: (pagination) => async (dispatch, getState) => {
		dispatch({
			type: ExceptionActions.PAGINATION_CHANGED,
			payload: pagination,
		});

		dispatch(ExceptionActions.doFetch());
	},

	doChangeSort: (sorter) => async (dispatch, getState) => {
		dispatch({
			type: ExceptionActions.SORTER_CHANGED,
			payload: sorter,
		});

		dispatch(ExceptionActions.doFetch());
	},

	addExceptionDay: (data) => async (dispatch) => {
		try {
		  const response = await service.addExceptionDay(data);
		  
		  dispatch(ExceptionActions.doFetch());
		  Message.success('Alta de excepción exitosa');
		} catch (error) {
			Errors.showMessage(error)
		}
	  },

	editExceptionDay: (data) => async (dispatch) => {
		try {
			await service.editExceptionDay(data);
			Message.success('Edición exitosa')
			dispatch(ExceptionActions.doFetch());
		} catch (error) {
			Errors.handle(error);
		}
	},

	deleteExceptionDay: (exceptionDayId) => async (dispatch) => {
		try {
			await service.deleteException(exceptionDayId);
			Message.success('Se ha eliminado con éxito')
			dispatch(ExceptionActions.doFetch());
		} catch (error) {
			// Errors.handle(error);
		}
	},



};

export default ExceptionActions;