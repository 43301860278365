import consolidatedService from "src/modules/export/export-consolidation/consolidatedService";
import consolidatedListSelectors from "./consolidatedListSelectors";
import Errors from "src/modules/shared/error/errors";
import Message from "src/view/shared/message";
import { getHistory } from "src/modules/store";
import { i18n } from 'src/i18n';

const prefix = "CONSOLIDATED_LIST";

const consolidatedListActions = {
  // Fetch users recipients
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,


  // Fetch Tipo de operación
  FETCH_OPERACIONES_TIPO_STARTED: `${prefix}_FETCH_OPERACIONES_TIPO_STARTED`,
  FETCH_OPERACIONES_TIPO_SUCCESS: `${prefix}_FETCH_OPERACIONES_TIPO_SUCCESS`,
  FETCH_OPERACIONES_TIPO_ERROR: `${prefix}_FETCH_OPERACIONES_TIPO_ERROR`,

  // Fetch users recipients
  FETCH_TURN_CONSOLIDATED_STARTED: `${prefix}_FETCH_TURN_CONSOLIDATED_STARTED`,
  FETCH_TURN_CONSOLIDATED_SUCCESS: `${prefix}_FETCH_TURN_CONSOLIDATED_SUCCESS`,
  FETCH_TURN_CONSOLIDATED_ERROR: `${prefix}_FETCH_TURN_CONSOLIDATED_ERROR`,

  // Fetch users recipients
  FETCH_USER_CONSOLIDATED_STARTED: `${prefix}_FETCH_USER_CONSOLIDATED_STARTED`,
  FETCH_USER_CONSOLIDATED_SUCCESS: `${prefix}_FETCH_USER_CONSOLIDATED_SUCCESS`,
  FETCH_USER_CONSOLIDATED_ERROR: `${prefix}_FETCH_USER_CONSOLIDATED_ERROR`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,

  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,
  CHANGE_FILTER_OPERATION: `${prefix}_CHANGE_FILTER_OPERATION`,
  CHANGE_FILTER: `${prefix}_CHANGE_FILTER`,
  SORTER_CHANGED_TURN: `${prefix}_SORTER_CHANGED_TURN`,

  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  STARTED_CONSOLIDATED_BY_TURN_ID:`${prefix}_STARTED_CONSOLIDATED_BY_TURN_ID`,

  GET_ALL_TRUCK_CONSOLIDATED: `${prefix}_GET_ALL_TRUCK_CONSOLIDATED`,

  GET_BYID_TRUCK_CONSOLIDATED: `${prefix}_GET_BYID_TRUCK_CONSOLIDATED`,

  GET_ALL_REMITO: `${prefix}_GET_ALL_REMITO`,

  CREATE_REMITO: `${prefix}_CREATE_REMITO`,

  GET_TRUCK_BY_ID: `${prefix}_GET_TRUCK_BY_ID`,

  GET_ALL_COMMODITY: `${prefix}_GET_ALL_COMMODITY`,

  COMMODITY_PAGINATION_CHANGED: `${prefix}_COMMODITY_PAGINATION_CHANGED`,

  GET_ALL_TRANSPORT: `${prefix}_GET_ALL_TRANSPORT`,

  TRANSPORT_OPTION: `${prefix}_TRANSPORT_OPTION`,

  EDIT_REMITO: `${prefix}_EDIT_REMITO`,

  REFRESH_REMITO:`${prefix}_ REFRESH_REMITO`,

  FETCH_REMITO_STARTED:`${prefix}_FETCH_REMITO_STARTED`,

  PRE_STATUS: `${prefix}_PRE_STATUS`,

  GET_STATUS: `${prefix}_GET_STATUS`,

  GET_ALL_PACKAGING: `${prefix}_GET_ALL_PACKAGING`,

  GET_ALL_PACKAGING_ORDER: `${prefix}_GET_ALL_PACKAGING_ORDER`,

  GET_ALL_TIME: `${prefix}_GET_ALL_TIME`,

  GET_ALL_TIME_ORDER: `${prefix}_GET_ALL_TIME_ORDER`,

  GET_TURN_IDCONSOLIDATED: `${prefix}_GET_TURN_IDCONSOLIDATED`,

  GET_ALL_TURN_CONSOLIDATED: `${prefix}_GET_ALL_TURN_CONSOLIDATED`,

  RESET_OFFSET: `${prefix}_RESET_OFFSET`,

  APPROVE_OPERATION_STARTED: `${prefix}_APPROVE_OPERATION_STARTED`,
  APPROVE_OPERATION_SUCCESS: `${prefix}_APPROVE_OPERATION_SUCCESS`,
  APPROVE_OPERATION_ERROR: `${prefix}_APPROVE_OPERATION_ERROR`,

  SERVICES_OPERATIONID: `${prefix}_SERVICES_OPERATIONID`,

  GET_SUCCESS_TURN: `${prefix}_GET_SUCCESS_TURN`,

  GET_ERROR_TURN: `${prefix}_GET_ERROR_TURN`,

  //tabla de consolidado - turnos.
  GET_ATTACHMENT: `${prefix}_GET_ATTACHMENT`,

  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  GET_CONSOLIDATED_BY_TURN_ID: `${prefix}_GET_CONSOLIDATED_BY_TURN_ID`,

  PAGINATION_CHANGED_TURNBYID: `${prefix}_PAGINATION_CHANGED_TURNBYID`,

  SORTER_CHANGED_TURNBYID: `${prefix}_SORTER_CHANGED_TURNBYID`,

  PAGINATION_REMITOS_CHANGED: `${prefix}_PAGINATION_REMITOS_CHANGED`,

  PAGINATION_TRUCKS_CHANGED: `${prefix}_PAGINATION_TRUCKS_CHANGED`,

  GET_STATUS_CONSOLIDATED: `${prefix}_GET_STATUS_CONSOLIDATED`,

  PAGINATION_TURN_CHANGED: `${prefix}_PAGINATION_TURN_CHANGED`,

  CHANGE_FILTER_TURN: `${prefix}_CHANGE_FILTER_TURN`,

  RESET_OFFSET_TURN: `${prefix}_RESET_OFFSET_TURN`,

  GET_DATA_BYTURN_STARTED: `${prefix}_GET_DATA_BYTURN_STARTED`,
  GET_DATA_BYTURN_SUCCESS: `${prefix}_GET_DATA_BYTURN_SUCCESS`,
  GET_DATA_BYTURN_ERROR: `${prefix}_GET_DATA_BYTURN_ERROR`,

  DELETE_REMITO: `${prefix}_DELETE_REMITO`,

  DELETE_TRUCK_STARTED: `${prefix}_DELETE_TRUCK_STARTED`,
  DELETE_TRUCK_SUCCESS: `${prefix}_DELETE_TRUCK_SUCCESS`,
  DELETE_TRUCK_ERROR: `${prefix}_DELETE_TRUCK_ERROR`,

  RESET_OFFSET_TURN_ID: `${prefix}_RESET_OFFSET_TURN_ID`,

  //EMBARQUES
  CHANGE_FILTER_EMBARQUE: `${prefix}_CHANGE_FILTER_EMBARQUE`,
  RESET_OFFSET_EMBARQUE: `${prefix}_RESET_OFFSET_EMBARQUE`,

  GET_EMBARQUES_STARTED: `${prefix}_GET_EMBARQUES_STARTED`,
  GET_EMBARQUES_SUCCESS: `${prefix}_GET_EMBARQUES_SUCCESS`,
  GET_EMBARQUES_ERROR: `${prefix}_GET_EMBARQUES_ERROR`,

  PAGINATION_EMBARQUE_CHANGED: `${prefix}_PAGINATION_EMBARQUE_CHANGED`,
  CREATE_EMBARQUE_STARTED: `${prefix}_CREATE_EMBARQUE_STARTED`,
  CREATE_EMBARQUE_SUCCESS: `${prefix}_CREATE_EMBARQUE_SUCCESS`,
  CREATE_EMBARQUE_ERROR: `${prefix}_CREATE_EMBARQUE_ERROR`,

  EDIT_EMBARQUE_STARTED: `${prefix}_EDIT_EMBARQUE_STARTED`,
  EDIT_EMBARQUE_SUCCESS: `${prefix}_EDIT_EMBARQUE_SUCCESS`,
  EDIT_EMBARQUE_ERROR: `${prefix}_EDIT_EMBARQUE_ERROR`,

  GET_CONSOLIDATION_TURN_ID_STARTED: `${prefix}_CONSOLIDATION_TURN_ID_STARTED`,
  GET_CONSOLIDATION_TURN_ID_SUCCESS: `${prefix}_CONSOLIDATION_TURN_ID_SUCCES`,
  GET_CONSOLIDATION_TURN_ID_ERROR: `${prefix}_CONSOLIDATION_TURN_ID_ERROR`,

  findBySelectConsolidationTurnID: (consolidationTurnId: string) => async (dispatch, getState) => {
    try {
      dispatch({
        type: consolidatedListActions.GET_CONSOLIDATION_TURN_ID_STARTED,
      });

      const {data} = await consolidatedService.getConsolidatedTurnById(consolidationTurnId);
      dispatch({
        type: consolidatedListActions.GET_CONSOLIDATION_TURN_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: consolidatedListActions.GET_CONSOLIDATION_TURN_ID_ERROR,
      });
    }
  },


  doFetch: (id?) => async (dispatch, getState) => {
    try {
      dispatch({
        type: consolidatedListActions.FETCH_STARTED,
      });

      const response = await consolidatedService.doFetch(
        consolidatedListSelectors.selectOrderBy(getState()),
        consolidatedListSelectors.selectLimit(getState()),
        consolidatedListSelectors.selectOffset(getState()),
        id === "operation" ? consolidatedListSelectors.selectFilterOperation(getState()) : consolidatedListSelectors.selectFilter(getState())
      );
      dispatch({
        type: consolidatedListActions.FETCH_SUCCESS,
        payload: response,
      });


    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: consolidatedListActions.FETCH_ERROR,
      });
    }
  },
  doFetchOperaciones: (id?) => async (dispatch, getState) => {
    try {
      dispatch({
        type: consolidatedListActions.FETCH_OPERACIONES_TIPO_STARTED,
      });

      const response = await consolidatedService.doFetchTipoOperacion();
      dispatch({
        type: consolidatedListActions.FETCH_OPERACIONES_TIPO_SUCCESS,
        payload: response,
      });


    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: consolidatedListActions.FETCH_OPERACIONES_TIPO_ERROR,
      });
    }
  },
  doFetchTurnConsolidated: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: consolidatedListActions.FETCH_TURN_CONSOLIDATED_STARTED,
      });

      const response = await consolidatedService.getTurnConsolidated(
        consolidatedListSelectors.selectOrderByTurn(getState()),
        consolidatedListSelectors.selectLimitTurn(getState()),
        consolidatedListSelectors.selectOffsetTurn(getState()),
        consolidatedListSelectors.selectFilterTurn(getState())
      );
      dispatch({
        type: consolidatedListActions.FETCH_TURN_CONSOLIDATED_SUCCESS,
        payload: response,
      });


    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: consolidatedListActions.FETCH_TURN_CONSOLIDATED_ERROR,
      });
    }
  },
  consolidatedGetUsers: (consolidadoTurnId) => async (dispatch) => {
    try {
      dispatch({
        type: consolidatedListActions.FETCH_USER_CONSOLIDATED_STARTED,
      });
      const response = await consolidatedService.consolidatedGetUsers(consolidadoTurnId);
      dispatch({
        type: consolidatedListActions.FETCH_USER_CONSOLIDATED_SUCCESS,
        payload: response,
      });
    } catch (error) {
      // Errors.handle(error);
      dispatch({
        type: consolidatedListActions.FETCH_USER_CONSOLIDATED_ERROR,
      });
    }
  },
  doChangePagination: (pagination) => async (dispatch) => {
    dispatch({
      type: consolidatedListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(consolidatedListActions.doFetch());
  },



  doChangeSort: (sorter) => async (dispatch) => {
    dispatch({
      type: consolidatedListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(consolidatedListActions.doFetch());
  },

  doChangeFilter: (filter) => async (dispatch) => {
    dispatch({
      type: consolidatedListActions.RESET_OFFSET,
    });

    dispatch({
      type: consolidatedListActions.CHANGE_FILTER,
      payload: filter,
    });

    dispatch(consolidatedListActions.doFetch());
  },

  doChangeFilterOperation: (filter) => async (dispatch) => {
    dispatch({
      type: consolidatedListActions.RESET_OFFSET,
    });

    dispatch({
      type: consolidatedListActions.CHANGE_FILTER_OPERATION,
      payload: filter,
    });

    dispatch(consolidatedListActions.doFetch("operation"));
  },

  doChangeFilterTurn: (filter) => async (dispatch) => {
    dispatch({
      type: consolidatedListActions.RESET_OFFSET_TURN,
    });

    dispatch({
      type: consolidatedListActions.CHANGE_FILTER_TURN,
      payload: filter,
    });

    dispatch(consolidatedListActions.doFetchTurnConsolidated());
  },


  doChangeSortTurn: (sorter) => async (dispatch) => {
    dispatch({
      type: consolidatedListActions.SORTER_CHANGED_TURN,
      payload: sorter,
    });

    dispatch(consolidatedListActions.doFetchTurnConsolidated());
  },

  //creacion de una reserva
  createConsolidated: (values) => async (dispatch, getState) => {

    try {
      await consolidatedService.createConsolidated(values);
      dispatch(consolidatedListActions.doFetch())
      Message.success('Se cargó la reserva con éxito')
    } catch (error) {
      // Errors.handle(error);
    }
  },
  deleteHeaderConsolidado: (values) => async (dispatch) => {
    try {
      const response = await consolidatedService.deleteHeaderConsolidado(values);
      Message.success(`${response.message}`)
      dispatch(consolidatedListActions.doFetch());
    } catch (error) {
      Errors.showMessage(error)
    }
  },



  consolidatedById: (values) => async (dispatch) => {

    try {
      dispatch({
        type: consolidatedListActions.INIT_STARTED,
      });
      const response = await consolidatedService.consolidatedById(values);
      dispatch({
        type: consolidatedListActions.INIT_SUCCESS,
        payload: response,
      });
    } catch (error) {
      // Errors.handle(error);
      dispatch({
        type: consolidatedListActions.INIT_ERROR,
      });
    }
  },
  consolidatedGetTruck: (values) => async (dispatch) => {
    try {
      const response = await consolidatedService.consolidatedGetTruck(values);
      dispatch({
        type: consolidatedListActions.GET_ALL_TRUCK_CONSOLIDATED,
        payload: response,
      });
    } catch (error) {
      // Errors.handle(error);
    }
  },
  consolidatedGetTurns: (consolidadoHeaderId) => async (dispatch) => {
    try {
      dispatch({
        type: consolidatedListActions.GET_SUCCESS_TURN,
      });
      const response = await consolidatedService.consolidatedGetTurns(consolidadoHeaderId);
      dispatch({
        type: consolidatedListActions.GET_ALL_TURN_CONSOLIDATED,
        payload: response,
      });
    } catch (error) {
      // Errors.handle(error);
      dispatch({
        type: consolidatedListActions.GET_ERROR_TURN,
      });
    }
  },


  getDataConsolidatedByTurnId: (consolidadoTurnId) => async (dispatch) => {
    try {
      dispatch({
        type: consolidatedListActions.GET_DATA_BYTURN_STARTED,
      });
      const response = await consolidatedService.getDataConsolidatedByTurnId(consolidadoTurnId);
      dispatch({
        type: consolidatedListActions.GET_DATA_BYTURN_SUCCESS,
        payload: response,
      });
    } catch (error) {
      // Errors.handle(error);
      dispatch({
        type: consolidatedListActions.GET_DATA_BYTURN_ERROR,
      });
    }
  },

  editConsolidatedTurn: (id, data, header?, handleClose?) => async (dispatch) => {
    try {
      const response = await consolidatedService.editConsolidatedTurn(id, data)
      Message.success(`${response.message}`)
      dispatch(consolidatedListActions.getTurnConsolidatedById(header))
      handleClose()
    } catch (error) {
      Errors.showMessage(error)
    }
  },

  deleteTurnConsolidado: (values, header) => async (dispatch) => {
    try {
      const response = await consolidatedService.deleteTurnConsolidado(values);
      Message.success(`${response.message}`)
      dispatch(consolidatedListActions.getTurnConsolidatedById(header))
    } catch (error) {
      Errors.showMessage(error)
    }
  },


  consolidatedGetTruckByTruckId: (values) => async (dispatch) => {
    try {
      const response = await consolidatedService.consolidatedGetTruckByTruckId(
        values
      );
      dispatch({
        type: consolidatedListActions.GET_BYID_TRUCK_CONSOLIDATED,
        payload: response,
      });
    } catch (error) {
      // Errors.handle(error);
    }
  },

  //modal mercaderia/camion
  createConsolidatedTruck: (values, setIdTruck?) => async (dispatch) => {

    try {
      const response = await consolidatedService.createConsolidatedTruck(values);
      setIdTruck(response?.data?.id);
      // dispatch(consolidatedListActions.getAllRemito(response?.data?.id))
      // dispatch(consolidatedListActions.getConsolidatedByTurnId(values?.consolidadoTurnId)); //cosulta remitos por id de camion (idtruck)
      Message.success(`Transporte guardado con éxito`);
    } catch (error) {
      Message.error('Turno cerrado.')
    }
  },
  //get de tabla mercaderias por id de turno
  getConsolidatedByTurnId: (consolidadoTurnId) => async (dispatch, getState) => {
    dispatch({
      type: consolidatedListActions.STARTED_CONSOLIDATED_BY_TURN_ID,
    });

    try {
      const response = await consolidatedService.getConsolidatedByTurnId(consolidadoTurnId,
        consolidatedListSelectors.selectOrderByTrucks(getState()),
        consolidatedListSelectors.selectLimitTrucks(getState()),
        consolidatedListSelectors.selectOffsetTrucks(getState()),
        consolidatedListSelectors.selectFilterTrucks(getState())
      );

      dispatch({
        type: consolidatedListActions.GET_CONSOLIDATED_BY_TURN_ID,
        payload: response,
      });
    } catch (error) {
      // Errors.handle(error);
    }
  },
  EditConsolidatedTruck: (values, id?) => async (dispatch) => {
    try {
      dispatch(consolidatedListActions.consolidatedGetTruckByTruckId(id));
    } catch (error) {
      Errors.handle(error);
    }
  },
  //creacion y edicion de un remito
  createRemito: (values?, consolidadoTurnId?, setNewLine?) => async (dispatch) => {
    try {
      const response = await consolidatedService.createRemito(values);
      dispatch({
        type: consolidatedListActions.CREATE_REMITO,
        payload: response,
      });
      dispatch(consolidatedListActions.getAllRemito(values?.consolidadoTruckId)); //tmb hacer fetch de tabla de mercaderias
      // dispatch(consolidatedListActions.getConsolidatedByTurnId(parseInt(consolidadoTurnId))); //tmb hacer fetch de tabla de mercaderias
      setNewLine(false)
      Message.success('Remito guardado con éxito.')
    } catch (error) {
      // Errors.handle(error);
    }
  },
  //edicion de un remito OK
  editRemito: (values?, id?, turnId?, setEdit?,) => async (dispatch) => {
    try {
      await consolidatedService.editRemito(values, id);
      dispatch(consolidatedListActions.getAllRemito(values?.consolidadoTruckId));
      // dispatch(consolidatedListActions.getConsolidatedByTurnId(parseInt(turnId))); //tmb hacer fetch de tabla de mercaderias

      // dispatch({
      //   type: consolidatedListActions.EDIT_REMITO,
      //   payload: response,
      // });
      setEdit(false);
      Message.success('Remito editado exitosamente')
      
    } catch (error) {
      Errors.handle(error);
    }
  },

  //remitos segun idtruck
  refreshRemito : (consolidadoTruckId?) => async (dispatch, getState) => {
    dispatch({
      type: consolidatedListActions.REFRESH_REMITO,
    });
  },
  getAllRemito: (consolidadoTruckId?) => async (dispatch, getState) => {
    try {
      dispatch({
        type: consolidatedListActions.FETCH_REMITO_STARTED,
      });
      const response = await consolidatedService.getAllRemito(consolidadoTruckId,
        consolidatedListSelectors.selectOrderByRemitos(getState()),
        consolidatedListSelectors.selectLimitRemitos(getState()),
        consolidatedListSelectors.selectOffsetRemitos(getState()),
        consolidatedListSelectors.selectFilterRemitos(getState())
      );
      dispatch({
        type: consolidatedListActions.GET_ALL_REMITO,
        payload: response,
      });

    } catch (error) {
    }
  },
  doChangePaginationRemitos: (pagination, idTruck?) => async (dispatch) => {

    dispatch({
      type: consolidatedListActions.PAGINATION_REMITOS_CHANGED,
      payload: pagination,
    });

    dispatch(consolidatedListActions.getAllRemito(idTruck));
  },

  doChangePaginationTrucks: (pagination, idTurn?) => async (dispatch) => {
    dispatch({
      type: consolidatedListActions.PAGINATION_TRUCKS_CHANGED,
      payload: pagination,
    });

    dispatch(consolidatedListActions.getConsolidatedByTurnId(idTurn));
  },

  doChangePaginationTurn: (pagination, idTurn?) => async (dispatch) => {
    dispatch({
      type: consolidatedListActions.PAGINATION_TURN_CHANGED,
      payload: pagination,
    });

    dispatch(consolidatedListActions.doFetchTurnConsolidated());
  },

  getTruckById: (values) => async (dispatch) => {
    try {
      const response = await consolidatedService.getTruckById(values);
      dispatch({
        type: consolidatedListActions.GET_TRUCK_BY_ID,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);
    }
  },

  //edicion mercaderia/camion 
  editTruck: (values, handleClose?, selectedTurn?) => async (dispatch) => {
    try {
      const response = await consolidatedService.editTruck(values)
      dispatch(consolidatedListActions.getConsolidatedByTurnId(selectedTurn?.id)); //idturn
      Message.success("Edición exitosa");
      handleClose();
    } catch (error) {
      Errors.handle(error);
    }
  },
  //borrar una mercaderia FUNCIONA OK
  deleteConsolidatedTruck: (values, selectedTurn?) => async (dispatch) => {
    dispatch({
      type: consolidatedListActions.DELETE_TRUCK_STARTED,
    });
    try {
      const response = await consolidatedService.deleteConsolidatedTruck(
        values
      );
      dispatch({
        type: consolidatedListActions.DELETE_TRUCK_SUCCESS,
      });
      dispatch(consolidatedListActions.getConsolidatedByTurnId(selectedTurn?.id)); //consolidadoturnid
      Message.success(`${response?.message}`);
    } catch (error) {
      dispatch({
        type: consolidatedListActions.DELETE_TRUCK_ERROR,
      });
      Message.error(`No se pudo eliminar porque el camión contiene remitos.`);
    }
  },
  //borrar remito de un camion
  deleteConsolidatedRemito: (idRemito, consolidadoTruckId?, turnId?) => async (dispatch) => {
    dispatch({
      type: consolidatedListActions.DELETE_REMITO,
    });
    try {
      const response = await consolidatedService.deleteRemito(idRemito);
      dispatch(consolidatedListActions.getAllRemito(consolidadoTruckId));
      // dispatch(consolidatedListActions.getConsolidatedByTurnId(parseInt(turnId)));

      Message.success(`${response.message}`);
    } catch (error) {
      Errors.handle(error);
      Message.error(`No se pudo eliminar`);
    }
  },
  saveTruck: (idConsolidated?, handleClose?, setIdTruck?) => async (
    dispatch
  ) => {
    dispatch(consolidatedListActions.consolidatedGetTruck(idConsolidated));
    Message.success(`Se guardo exitosamente`);
    setIdTruck(null);
    handleClose();
  },

  getCommodity: () => async (dispatch) => {

    try {
      const response = await consolidatedService.getCommodity();
      dispatch({
        type: consolidatedListActions.GET_ALL_COMMODITY,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);
    }
  },
  getAllCommodity: () => async (dispatch) => {

    try {
      const response = await consolidatedService.getAllCommodity();
      dispatch({
        type: consolidatedListActions.GET_ALL_COMMODITY,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);
    }
  },
  getPackaging: () => async (dispatch) => {
    try {
      const response = await consolidatedService.getAllPackaging();
      dispatch({
        type: consolidatedListActions.GET_ALL_PACKAGING,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);
    }
  },

  getOrderPackaging: () => async (dispatch) => {
    try {
      const response = await consolidatedService.getPackaging();
      dispatch({
        type: consolidatedListActions.GET_ALL_PACKAGING_ORDER,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);
    }
  },

  getTimeFring: () => async (dispatch) => {
    try {
      const response = await consolidatedService.getTimeFring();
      dispatch({
        type: consolidatedListActions.GET_ALL_TIME,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);
    }
  },

  getOrderTime: () => async (dispatch) => {
    try {
      const response = await consolidatedService.getTimeFring();
      dispatch({
        type: consolidatedListActions.GET_ALL_TIME_ORDER,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);
    }
  },

  doChangeCommodityPagination: () => async (dispatch) => {
    try {
      const response = await consolidatedService.getCommodity();
      dispatch({
        type: consolidatedListActions.COMMODITY_PAGINATION_CHANGED,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);
    }
  },

  getTransport: () => async (dispatch) => {
    try {
      const response = await consolidatedService.getTransport();
      dispatch({
        type: consolidatedListActions.GET_ALL_TRANSPORT,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);
    }
  },
  getTransportOptions: () => async (dispatch) => {
    try {
      const response = await consolidatedService.getTransport();
      dispatch({
        type: consolidatedListActions.TRANSPORT_OPTION,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);
    }
  },

  getPreStatus: () => async (dispatch) => {
    try {
      const response = await consolidatedService.getPreStatus();
      dispatch({
        type: consolidatedListActions.PRE_STATUS,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);
    }
  },

  getStatus: () => async (dispatch) => {

    try {
      const response = await consolidatedService.getStatus();
      dispatch({
        type: consolidatedListActions.GET_STATUS,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);
    }
  },

  editHeaderConsolidatedTurn: (id, obj) => async (dispatch) => {
    try {
      const response = await consolidatedService.editHeaderConsolidatedTurn(id, obj)
      dispatch({
        type: consolidatedListActions.INIT_SUCCESS,
        payload: response,
      }); //para que se actualice el selector y me muestre el booking editado correctamente en las mercaderias/camiones

      dispatch(consolidatedListActions.getTurnConsolidatedById(id))
      dispatch(consolidatedListActions.consolidatedById(id))
      Message.success(`Modificación De Consolidado con Éxito`)

    } catch (error) {
      Errors.showMessage(error)
    }
  },

  createTurnConsolidated: (data) => async (dispatch) => {
    try {
      const response = await consolidatedService.createTurnConsolidated(data)

      Message.success(`${response.message}`)

      getHistory().replace(`/consolidated/turnlogistics/turn/${parseInt(data.consolidadoHeaderId)}`)


    } catch (error) {
      Errors.handle(error)
    }
  },
  backPageConsolidated: (data) => async (dispatch,) => {
    getHistory().replace(`/consolidated/turnlogistics/turn/${data.consolidadoHeaderId}`)
  },

  getTurnConsolidatedById: (data) => async (dispatch, getState) => {
    try {
      const response = await consolidatedService.getTurnConsolidatedById(data,
        consolidatedListSelectors.selectOrderByTurnById(getState()),
        consolidatedListSelectors.selectLimitTurnById(getState()),
        consolidatedListSelectors.selectOffsetTurnById(getState()))
      dispatch({
        type: consolidatedListActions.GET_TURN_IDCONSOLIDATED,
        payload: response
      })

    } catch (error) {
      Errors.handle(error)
    }
  },

  doChangePaginationTurnById: (pagination, idHeader) => async (dispatch) => {
    dispatch({
      type: consolidatedListActions.PAGINATION_CHANGED_TURNBYID,
      payload: pagination,
    });

    dispatch(consolidatedListActions.getTurnConsolidatedById(idHeader));
  },



  doChangeSortTurnById: (sorter, idHeader?) => async (dispatch) => {
    dispatch({
      type: consolidatedListActions.SORTER_CHANGED_TURNBYID,
      payload: sorter,
    });

    dispatch(consolidatedListActions.getTurnConsolidatedById(idHeader));
  },

  serviceEdition: (id, data,handleClose) => async (dispatch) => {
    try {

      const response = await consolidatedService.serviceEdition(id, data)
      Message.success(`${response?.message}`)
      handleClose()
    } catch (error) {
      Errors.handle(error)
    }
  },
  getAttachment: (data) => async (dispatch) => {
    try {
      const response = await consolidatedService.getAttachment(data)
      dispatch({
        type: consolidatedListActions.GET_ATTACHMENT,
        payload: response
      })
    } catch (error) {
      Errors.handle(error)
    }
  },

  createAttachment: (id, data) => async (dispatch) => {
    try {
      await consolidatedService.createAttachment(id, data)
      dispatch(consolidatedListActions.getAttachment(id))
    } catch (error) {
      Errors.handle(error)
    }
  },
  doChangeFilterEmbarques: (filter) => async (dispatch) => {
    dispatch({
      type: consolidatedListActions.RESET_OFFSET_EMBARQUE,
    });
    dispatch({
      type: consolidatedListActions.CHANGE_FILTER_EMBARQUE,
      payload: filter,
    });

    dispatch(consolidatedListActions.getEmbarques());
  },
  doChangePaginationEmbarque: (pagination) => async (dispatch) => {
    dispatch({
      type: consolidatedListActions.PAGINATION_EMBARQUE_CHANGED,
      payload: pagination,
    });

    dispatch(consolidatedListActions.getEmbarques());
  },
  getEmbarques: (data?) => async (dispatch, getState) => {
    try {
      dispatch({
        type: consolidatedListActions.GET_EMBARQUES_STARTED,
      });
      const response = await consolidatedService.getEmbarques(
        consolidatedListSelectors.selectOrderByEmbarque(getState()),
        consolidatedListSelectors.selectLimitEmbarque(getState()),
        consolidatedListSelectors.selectOffsetEmbarque(getState()),
        consolidatedListSelectors.selectFilterEmbarque(getState()),
        data
      );
      dispatch({
        type: consolidatedListActions.GET_EMBARQUES_SUCCESS,
        payload: response
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: consolidatedListActions.GET_EMBARQUES_ERROR,
      });
    }
  },


  createEmbarque: (data, files) => async (dispatch, getState) => {
    try {
      dispatch({
        type: consolidatedListActions.CREATE_EMBARQUE_STARTED,
      });
      await consolidatedService.createEmbarque(data, files);

      dispatch({
        type: consolidatedListActions.CREATE_EMBARQUE_SUCCESS,
      });

      Message.success(i18n('Creación embarque exitosa'));

      dispatch(consolidatedListActions.getEmbarques(data.consolidadoHeaderId));
    } catch (error) {

      Message.error(i18n(Errors.selectMessage(error)));

      dispatch({
        type: consolidatedListActions.CREATE_EMBARQUE_ERROR,
      });
    }
  },

  editEmbarque: (data, files) => async (dispatch, getState) => {
    try {
      dispatch({
        type: consolidatedListActions.EDIT_EMBARQUE_STARTED,
      });
      await consolidatedService.editEmbarque(data, files);

      dispatch({
        type: consolidatedListActions.EDIT_EMBARQUE_SUCCESS,
      });

      Message.success(i18n('Edición embarque exitosa'));

      dispatch(consolidatedListActions.getEmbarques(data.consolidadoHeaderId));
    } catch (error) {

      Message.error(i18n(Errors.selectMessage(error)));

      dispatch({
        type: consolidatedListActions.EDIT_EMBARQUE_ERROR,
      });
    }
  },

  doDestroyEmbarque: (id, consolidation) => async (dispatch, getState) => {
    try {
      dispatch({
        type: consolidatedListActions.DESTROY_STARTED,
      });

      await consolidatedService.deleteEmbarque(id);

      dispatch({
        type: consolidatedListActions.DESTROY_SUCCESS,
      });

      Message.success(i18n('Permiso eliminado exitosamente'));

      dispatch(consolidatedListActions.getEmbarques(consolidation));
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: consolidatedListActions.DESTROY_ERROR,
      });

      dispatch(consolidatedListActions.getEmbarques(consolidation));
    }
  },

  closeTurn: (consolidadoTurnId, setClosed, idHeader) => async (dispatch) => {
    setClosed(true)
    dispatch({
      type: consolidatedListActions.APPROVE_OPERATION_STARTED,
    });
    try {
      const response = await consolidatedService.closeTurn(consolidadoTurnId);
      dispatch({
        type: consolidatedListActions.APPROVE_OPERATION_SUCCESS,
        payload: response,
      });
      if (response.code === "MSCO-EXPRT-LCL2-008-200") {
        Message.success(`Se ha cerrado correctamente el turno`)
        setClosed(true)
      }
      
      dispatch(consolidatedListActions.consolidatedGetTurns(idHeader));
      
    } catch (error) {
      dispatch({
        type: consolidatedListActions.APPROVE_OPERATION_ERROR,
      });
      Errors.showMessage(error);
      setClosed(false)
    }
  },

  getServicesForOperationId: (data?) => async (dispatch) => {
    try {
      const response = await consolidatedService.getServicesForOperationId(data);
      dispatch({
        type: consolidatedListActions.SERVICES_OPERATIONID,
        payload: response,
      });

    } catch (error) {
      Errors.handle(error);
    }
  },

  doAllServices: () => async (dispatch) => {
    try {
      const response = await consolidatedService.doAllService();
      dispatch({
        type: consolidatedListActions.SERVICES_OPERATIONID,
        payload: response?.list || [],
      });

    } catch (error) {
      Errors.handle(error);
    }
  },


  //estados 
  getStatusConsolidated: (data) => async (dispatch) => {
    try {
      const response = await consolidatedService.getStatusConsolidated(data);
      dispatch({
        type: consolidatedListActions.GET_STATUS_CONSOLIDATED,
        payload: response,
      });

    } catch (error) {
      Errors.handle(error);
    }
  },
  //edicion de un turno
  editTurnConsolidated: (data?) => async (dispatch) => {
    try {
      const response = await consolidatedService.editTurnConsolidated(data)
      Message.success(`Turno editado con éxito`)
      getHistory().replace(`/consolidated/turnlogistics/turn/${data.consolidadoHeaderId}`)
    } catch (error) {
    }
  },

  // crear turno de consolidaddo en otro horario 
  scheduledOther: (data: any) => async (dispatch) => {
    try {
      const response = await consolidatedService.scheduledOther(data)
      Message.success(`Turno en otro horario creado con éxito`)
      getHistory().replace(`/consolidated/turnlogistics/turn/${data.idHeader}`)
    } catch (error) {
    }
  },
  // editar turno de consolidaddo en otro horario 
  editScheduledOther: (data: any) => async (dispatch) => {
    try {
      const response = await consolidatedService.editScheduledOther(data)
      Message.success(`Turno en otro horario editado con éxito`)
      getHistory().replace(`/consolidated/turnlogistics/turn/${data.idHeader}`)
    } catch (error) {
    }
  },

};

export default consolidatedListActions;


