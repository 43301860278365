import authAxios from 'src/modules/shared/axios/authAxios';
import { AuthToken } from 'src/modules/auth/authToken';
import * as qs from 'qs';
import { validationRoles } from 'src/view/shared/ValidationResources';

export default class AuthService {
	static async signinWithEmailAndPassword(username, password) {
		var data = qs.stringify({
			grant_type: 'password',
			username: username,
			password: password,
		});

		const response = await authAxios.post('/azure-ad/oauth/token', data, {
			headers: {
				Authorization: 'Basic Zm9vQ2xpZW50OmZvb1NlY3JldA==',
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		});

		return response.data;
	}

	static async fetchMe() {
		const response = await authAxios.get('/azure-ad/profile/me');	
		console.log(response.data)
		validationRoles(response.data)
		return response.data;
	}

	static signout() {
		AuthToken.clearToken();
	}

	static async updateProfile(data) {
		const body = {
			data,
		};

		const response = await authAxios.put('/uaa/auth/profile', body);

		return response.data;
	}

	static async signUp(data) {
		const body = data
		
		const response = await authAxios.post('/portal/user/sendMailNewUsers', body);

		return response.data;
	}

	static async forgotPassword(data) {
		
		const response = await authAxios.post('/portal/notification/notify-change-password', data);

		return response.data;

	}
}
