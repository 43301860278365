import service from '../exportService';
import exportTrucksSelectors from './exportTrucksSelectors';

const prefix = 'EXPORT_TRUCKS';

const exportTrucksActions = {
	FETCH_STARTED: `${prefix}_FETCH_STARTED`,
	FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
	FETCH_ERROR: `${prefix}_FETCH_ERROR`,

	PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,

	SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

	CHANGE_FILTER: `${prefix}_CHANGE_FILTER`,

	RESET_PAGINATION: `${prefix}_RESET_PAGINATION`,

	CHANGE_FILTER_LABELS: `${prefix}_CHANGE_FILTER_LABELS`,


	doFetch: (cuitSelected?) => async (dispatch, getState) => {
		try {
			dispatch({
				type: exportTrucksActions.FETCH_STARTED,
			});

			const response = await service.getAllTrucks(
				exportTrucksSelectors.selectOrderBy(getState()),
				exportTrucksSelectors.selectLimit(getState()),
				exportTrucksSelectors.selectOffset(getState()),
				exportTrucksSelectors.selectFilter(getState()),
				cuitSelected
			);

			dispatch({
				type: exportTrucksActions.FETCH_SUCCESS,
				payload: response,
			});

		} catch (error) {
			dispatch({
				type: exportTrucksActions.FETCH_ERROR,
			});
		}
	},

	doChangeFilterLabel: (values) => async (dispatch) => {
		console.log(values)
		dispatch({
			type: exportTrucksActions.CHANGE_FILTER_LABELS,
			payload: values,
		});

	},

	doChangePagination: (pagination) => async (dispatch) => {
		dispatch({
			type: exportTrucksActions.PAGINATION_CHANGED,
			payload: pagination,
		});

		dispatch(exportTrucksActions.doFetch());
	},

	doChangeSort: (sorter) => async (dispatch) => {
		dispatch({
			type: exportTrucksActions.SORTER_CHANGED,
			payload: sorter,
		});

		dispatch(exportTrucksActions.doFetch());
	},

	doChangeFilter: (values) => async (dispatch, getState) => {
		const currentState = getState();
		const currentCuitSelected = currentState.exportTrucks?.cuitSelected;

		// if (values.cuit !== currentCuitSelected) {
		// 	dispatch(exportTrucksActions.doResetPagination()); //resetea paginacion cad vez que cambia el cuit seleccionado
		// }
		dispatch(exportTrucksActions.doResetPagination()); 
		dispatch({
			type: exportTrucksActions.CHANGE_FILTER,
			payload: values,
		});

		dispatch(exportTrucksActions.doFetch(values.cuit));
	},

	doResetPagination: () => async (dispatch) => {
		dispatch({
			type: exportTrucksActions.RESET_PAGINATION,
		});

	},
};

export default exportTrucksActions;
