/* eslint-disable import/no-anonymous-default-export */
import actions from "src/modules/export/export-consolidation/form/consolidatedFormActions";

// const INITIAL_PAGE_SIZE = 10;

const initialData = {
  consolidatedHeader: {},
  commodities: [],
  typeOfLoad: [],
  packaging: [],
  operations: [],
  saveLoading: false,
  approved: false,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.APPROBATION_RESET) {
    return {
      ...state,
      saveLoading: true,
      approved: false,
    };
  }

  if (type === actions.APPROBATION_STARTED) {
    return {
      ...state,
      saveLoading: true,
      approved: false,
    };
  }

  if (type === actions.APPROBATION_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
      approved: true,
    };
  }

  if (type === actions.APPROBATION_ERROR) {
    return {
      ...state,
      saveLoading: false,
      approved: false,
    };
  }
  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      rows: payload.list,
      count: payload.pageSize,
      total: payload.total,
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      rows: [],
      count: 0,
    };
  }

  if (type === actions.GET_COMMODITIES_STARTED) {
    return {
      ...state,
      commodities: [],
    };
  }

  if (type === actions.GET_COMMODITIES_SUCCESS) {
    return {
      ...state,
      commodities: payload,
    };
  }

  if (type === actions.GET_COMMODITIES_ERROR) {
    return {
      ...state,
      commodities: [],
    };
  }

  if (type === actions.GET_TYPEOFLOAD_STARTED) {
    return {
      ...state,
      typeOfLoad: [],
    };
  }

  if (type === actions.GET_TYPEOFLOAD_SUCCESS) {
    return {
      ...state,
      typeOfLoad: payload,
    };
  }

  if (type === actions.GET_TYPEOFLOAD_ERROR) {
    return {
      ...state,
      typeOfLoad: [],
    };
  }

  if (type === actions.GET_OPERATIONS_STARTED) {
    return {
      ...state,
      operations: [],
    };
  }

  if (type === actions.GET_OPERATIONS_SUCCESS) {
    return {
      ...state,
      operations: payload,
    };
  }

  if (type === actions.GET_OPERATIONS_ERROR) {
    return {
      ...state,
      operations: [],
    };
  }
  if (type === actions.GET_PACKAGING_STARTED) {
    return {
      ...state,
      packaging: [],
    };
  }

  if (type === actions.GET_PACKAGING_SUCCESS) {
    return {
      ...state,
      packaging: payload,
    };
  }

  if (type === actions.GET_PACKAGING_ERROR) {
    return {
      ...state,
      packaging: [],
    };
  }

  if (type === actions.CONSOLIDATED_HEADER_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.CONSOLIDATED_HEADER_SUCCESS) {
    return {
      ...state,
      loading: false,
      consolidatedHeader: payload,
    };
  }

  if (type === actions.CONSOLIDATED_HEADER_ERROR) {
    return {
      ...state,
      consolidatedHeader: {},
    };
  }
  return state;
};
