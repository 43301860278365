import layoutActions from 'src/modules/layout/layoutActions';
import { useDispatch } from 'react-redux';
import { AppBar, Toolbar, IconButton, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import UserMenu from 'src/view/layout/UserMenu';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	appBar: {
		backgroundColor: 'white',
		color: theme.palette.primary.main,
		zIndex: theme.zIndex.drawer + 1,
	},
	logo: {
		paddingLeft: '100px',
		display: 'flex',
		alignItems: 'center',
	},
	grow: {
		flex: '1 1 auto',
	},
}));

function Header(props) {
	const dispatch = useDispatch();
	const classes = useStyles();

	return (
		<AppBar className={classes.appBar} position='fixed'>
			<Toolbar>
				<IconButton edge='start' onClick={() => dispatch(layoutActions.doToggleMenu())} color='inherit'>
					<MenuIcon />
				</IconButton>
				<Link to='/home' className={classes.logo} onClick={() => dispatch(layoutActions.doHideMenu())}>
					<img src='/images/NuevoLogo.png' alt='Logo Terminal Zárate' style={{
						maxWidth:'225px',
						maxHeight:'77px'
					}} />
				</Link>
				<div className={classes.grow} />
				<UserMenu />
			</Toolbar>
		</AppBar>
	);
}

export default Header;
