import providerCompanyService from 'src/modules/providerCompany/providerCompanyService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import providersDialogsActions from '../dialogs/providersDialogsActions';
import providerCompanyListSelectors from './providerCompanyListSelectors';
import emptyActions from 'src/modules/empty/list/emptyListActions';

const prefix = 'PROVIDER_COMPANY';

const companyFormActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  USERS_STARTED: `${prefix}_USERS_STARTED`,
  USERS_SUCCESS: `${prefix}_USERS_SUCCESS`,
  USERS_ERROR: `${prefix}_USERS_ERROR`,

  RELATION_STARTED: `${prefix}_RELATION_STARTED`,
  RELATION_SUCCESS: `${prefix}_RELATION_SUCCESS`,
  RELATION_ERROR: `${prefix}_RELATION_ERROR`,

  USER_PROVIDER_STARTED: `${prefix}_USER_PROVIDER_STARTED`,
  USER_PROVIDER_SUCCESS: `${prefix}_USER_PROVIDER_SUCCESS`,
  USER_PROVIDER_ERROR: `${prefix}_USER_PROVIDER_ERROR`,

  EDIT_PROVIDER_SUCCESS: `${prefix}_EDIT_PROVIDER_SUCCESS`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,
  CHANGE_FILTER: `${prefix}_USER_CHANGE_FILTER`,

  RESET_CODE: `${prefix}_RESET_CODE`,

  GET_STAKEHOLDER_USERS_STARTED: `${prefix}GET_STAKEHOLDER_USERS_STARTED`,
  GET_STAKEHOLDER_USERS_SUCCESS: `${prefix}GET_STAKEHOLDER_USERS_SUCCESS`,
  GET_STAKEHOLDER_USERS_ERROR: `${prefix}GET_STAKEHOLDER_USERS_ERROR`,

  RESET_PAGINATION: `${prefix}_RESET_PAGINATION`,
	GET_FILTERPREVIEW:`${prefix}_GET_FILTERPREVIEW`,


  CLEAN_FILTER: `${prefix}CLEAN_FILTER`,

  doResetCode: () => (dispatch) => {
    dispatch({
      type: companyFormActions.RESET_CODE,
    });
  },


  cleanFilter: () => (dispatch) => {
    dispatch({
      type: companyFormActions.CLEAN_FILTER,
    });
  },


  doFetchCompany: (cuit?, clientCompanyId?) => async (dispatch, getState) => {
    try {
      dispatch({
        type: companyFormActions.FETCH_STARTED,
        payload: { cuit },
      });
      const code = providerCompanyListSelectors.selectError(getState())
      const response = await providerCompanyService.find(cuit, clientCompanyId);

      dispatch({
        type: companyFormActions.FETCH_SUCCESS,
        payload: response
      });

      if (code === 'MS-CO-OP003-ERR-001') {
        return dispatch(providersDialogsActions.doToggleAdd())
      }

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: companyFormActions.FETCH_ERROR,
      });
    }
  },

  doFetchUsers: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: companyFormActions.USERS_STARTED,
      });

      const response = await providerCompanyService.fetchUsers(
        providerCompanyListSelectors.selectOrderBy(getState()),
        providerCompanyListSelectors.selectLimit(getState()),
        providerCompanyListSelectors.selectOffset(getState()),
        providerCompanyListSelectors.selectFilter(getState())
      );

      dispatch({
        type: companyFormActions.USERS_SUCCESS,
        payload: response
      });

    } catch (error) {
      dispatch({
        type: companyFormActions.USERS_ERROR,
      });
    }
  },

  getUsersAdminByStakeholderId: (id) => async (dispatch) => {
    try {
      dispatch({
        type: companyFormActions.GET_STAKEHOLDER_USERS_STARTED,
      });

      const response = await providerCompanyService.getStakeholderUsers(id);
      dispatch({
        type: companyFormActions.GET_STAKEHOLDER_USERS_SUCCESS,
        payload: response
      });
    } catch {
      dispatch({
        type: companyFormActions.GET_STAKEHOLDER_USERS_ERROR,
      });
    }
  },

  doRelation: (stakeholderCuit?, companyId?) => async (dispatch, getState) => {
    try {
      dispatch({
        type: companyFormActions.RELATION_STARTED,
        payload: { stakeholderCuit, companyId },
      });

      const response = await providerCompanyService.createRelation(stakeholderCuit, companyId);
      dispatch({
        type: companyFormActions.RELATION_SUCCESS,
        payload: response
      });
      dispatch(companyFormActions.doFetchUsers());
      Message.success(`${response.message}`) //relacion con provedor... establecida

    } catch (error) {
      dispatch({
        type: companyFormActions.RELATION_ERROR,
      });
    }
  },

  doCreateUserProvider: (cuit?, companyName = "", clientCompanyId?, data?, setOpenForm?) => async (
    dispatch,
    getState,
  ) => {
    dispatch({
      type: companyFormActions.USER_PROVIDER_STARTED,
    });
    try {
      const response = await providerCompanyService.createUserProvider(cuit, companyName, clientCompanyId, data);

      dispatch({
        type: companyFormActions.USER_PROVIDER_SUCCESS,
        payload: response
      });
      dispatch(emptyActions.getStakeholdersNotPagination());
      dispatch(companyFormActions.doFetchUsers());
      setOpenForm(false)
      if (response.code === "MSCO-STK-003-404") {
        Message.error(`${response.message}`)
      }

    } catch (error) {
      dispatch({
        type: companyFormActions.USER_PROVIDER_ERROR,
      });
    }
  },

  editUserProvider: (stakeholderId, clientCompanyId) => async (
    dispatch,
    getState,
  ) => {
    try {

      const response = await providerCompanyService.editUserProvider(stakeholderId, clientCompanyId);

      dispatch({
        type: companyFormActions.EDIT_PROVIDER_SUCCESS,
        payload: response
      });
      // dispatch(providersDialogsActions.doToggleAdd()) //cierra el modal
      dispatch(companyFormActions.doFetchUsers());

      Message.success('Despachante editado con éxito')
    } catch (error) {

    }
  },

  deleteStakeholder: (id, clientCompanyId) => async (dispatch) => {
    try {
      const response = await providerCompanyService.deleteStakeholder(id, clientCompanyId);
      dispatch(companyFormActions.doFetchUsers());
      Message.success(`${response}`);
    } catch (error) {
      Errors.handle(error);
    }
  },


  doChangePagination: (pagination) => async (dispatch) => {
    dispatch({
      type: companyFormActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(companyFormActions.doFetchUsers());
  },

  doChangeSort: (sorter) => async (dispatch) => {
    dispatch({
      type: companyFormActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(companyFormActions.doFetchUsers());
  },

  doChangeFilter: (values) => async (dispatch) => {

    dispatch(companyFormActions.doResetPagination()); //resetea paginacion cad vez que cambia el cuit seleccionado
    console.log(values)
    dispatch({
      type: companyFormActions.CHANGE_FILTER,
      payload: values,
    });

    dispatch(companyFormActions.doFetchUsers());
  },

  doResetPagination: () => async (dispatch) => {
    dispatch({
      type: companyFormActions.RESET_PAGINATION,
    });

  },

  editPorivder: (id, data, idStakeholder?, setOpenForm?, reset?) => async (dispatch) => {
    try {

      const response = await providerCompanyService.editProvider(id, data);
      dispatch(companyFormActions.getUsersAdminByStakeholderId(idStakeholder))
      Message.success(`${response?.message}`)
      setOpenForm(false)
      reset && reset()

    } catch (error) {
      Errors.showMessage(error)
    }
  },

  switchUser: (row, idStakeholder?, setOpenForm?) => async (dispatch) => {
    const {
      id,
      enabled,
      firstName,
      lastName
    } = row;


    try {
      enabled && await providerCompanyService.deleteProvider(id);
      enabled || await providerCompanyService.activeProvider(id);
      dispatch(companyFormActions.getUsersAdminByStakeholderId(idStakeholder))
      Message.success(`El usuario ${firstName} ${lastName} se ha ${enabled ? "desactivado" : "activado"} correctamente`);
      setOpenForm(false)
    } catch (error) {
      Errors.showMessage(error)
    }
  },
  FilterPreview: (values) => async (dispatch) => {
		try {
		  dispatch({
			type: companyFormActions.GET_FILTERPREVIEW,
			payload: values,
		  });
		} catch (error) {
		  Errors.showMessage(error);
		}
	  },

};

export default companyFormActions;
