import Errors from 'src/modules/shared/error/errors';
import service from '../bookingsService';
import selectors from './bookingListSelectors';

const prefix = 'BOOKINGS_LIST';

const bookingsListActions = {
	FETCH_STARTED: `${prefix}_FETCH_STARTED`,
	FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
	FETCH_ERROR: `${prefix}_FETCH_ERROR`,

	PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,

	SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

	UPDATE_FILTER: `${prefix}_UPDATE_FILTER`,

	FETCH_STARTED_CONTAINERS : `${prefix}_FETCH_STARTED_CONTAINERS`,

	doFetch: () => async (dispatch, getState) => {
		try {
			dispatch({
				type: bookingsListActions.FETCH_STARTED,
			});

			const response = await service.getAllBookings(
				selectors.selectOrderBy(getState()),
				selectors.selectLimit(getState()),
				selectors.selectOffset(getState()),
				selectors.selectFilter(getState())
			);

			dispatch({
				type: bookingsListActions.FETCH_SUCCESS,
				payload: response,
			});
		} catch (error) {
			Errors.handle(error);

			dispatch({
				type: bookingsListActions.FETCH_ERROR,
			});
		}
	},
	doFetchBookingDetails: () => async (dispatch, getState) => {
		try {
			dispatch({
				type: bookingsListActions.FETCH_STARTED,
			});

			const response = await service.getBookingDetails(
				selectors.selectOrderBy(getState()),
				selectors.selectLimit(getState()),
				selectors.selectOffset(getState()),
				selectors.selectFilter(getState())
			);

			dispatch({
				type: bookingsListActions.FETCH_SUCCESS,
				payload: response,
			});
		} catch (error) {
			Errors.handle(error);

			dispatch({
				type: bookingsListActions.FETCH_ERROR,
			});
		}
	},

	doChangePagination: (pagination) => async (dispatch, getState) => {
		dispatch({
			type: bookingsListActions.PAGINATION_CHANGED,
			payload: pagination,
		});

		dispatch(bookingsListActions.doFetch());
	},

	doChangeSort: (sorter) => async (dispatch, getState) => {
		dispatch({
			type: bookingsListActions.SORTER_CHANGED,
			payload: sorter,
		});

		dispatch(bookingsListActions.doFetch());
	},

	doUpdateFilter: (values) => async (dispatch, getState) => {
		
		try {
			dispatch({
				type: bookingsListActions.UPDATE_FILTER,
			});

			const response = await service.getFilterBookings(
				selectors.selectOrderBy(getState()),
				selectors.selectLimit(getState()),
				selectors.selectOffset(getState()),
				selectors.selectFilter(getState()),
				values
			);

			dispatch({
				type: bookingsListActions.FETCH_SUCCESS,
				payload: response,
			});
		} catch (error) {
			Errors.handle(error);

			dispatch({
				type: bookingsListActions.FETCH_ERROR,
			});
		}
	},
	doFetchContainers: () => async (dispatch, getState) => {
		
		try {
			dispatch({
				type: bookingsListActions.FETCH_STARTED_CONTAINERS,
			});

			const response = await service.getAllContainers(
				selectors.selectOrderBy(getState()),
				selectors.selectLimit(getState()),
				selectors.selectOffset(getState()),
				selectors.selectFilter(getState()),
			);

			dispatch({
				type: bookingsListActions.FETCH_SUCCESS,
				payload: response,
			});
		} catch (error) {
			Errors.handle(error);

			dispatch({
				type: bookingsListActions.FETCH_ERROR,
			});
		}
	},
};

export default bookingsListActions;