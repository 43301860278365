import authAxios2 from "../shared/axios/authAxios2";

export default class CheckingAccountService {
  static async getCheckingAccount(cuit?, orderBy?, limit?, offset?, filter?) {
    const params = {
      orderBy: orderBy,
      size: limit,
      page: offset,
      nrocomp: filter?.nrocomp || undefined,
      formud: filter?.formud || undefined,
      fchVncHasta: filter?.fchVncHasta || undefined,
      fchVncDesde: filter?.fchVncDesde || undefined,
      fchMovHasta: filter?.fchMovHasta || undefined,
      fchMovDesde: filter?.fchMovDesde || undefined,
    };

    const response = await authAxios2.get(
      `/portal/softland/cta-corriente/${cuit}`,
      { params }
    );

    return response.data;
  }
}
