import { createSelector } from "reselect";

const selectRaw = (state) => state.auth;

const selectAuthenticationUser = createSelector(
  [selectRaw],
  (auth) => auth.authenticationUser
);

const selectCurrentUser = createSelector(
  [selectRaw],
  (auth) => auth.currentUser
);

const selectCurrentUserUserType = createSelector(
  [selectCurrentUser],
  ({ userType }) => userType
);

const selectCurrentUserEmail = createSelector(
  [selectCurrentUser],
  (currentUser) => (currentUser ? currentUser.email : null)
);

const selectCurrentUserFullName = createSelector(
  [selectCurrentUser],
  (currentUser) => (currentUser ? currentUser.fullName : "")
);

const selectSignedIn = createSelector(
  [selectCurrentUser],
  (currentUser) => Boolean(currentUser) && Boolean(currentUser.id)
);

const selectLoading = createSelector([selectRaw], (auth) =>
  Boolean(auth.loading)
);

const selectActionSend = createSelector([selectRaw], (auth) =>
  Boolean(auth.actionSend)
);

const selectLoadingInit = createSelector([selectRaw], (auth) =>
  Boolean(auth.loadingInit)
);

const selectLoadingUpdateProfile = createSelector([selectRaw], (auth) =>
  Boolean(auth.loadingUpdateProfile)
);

const selectErrorMessage = createSelector(
  [selectRaw],
  (auth) => auth.errorMessage
);

const selectCurrentUserNameOrEmailPrefix = createSelector(
  [selectCurrentUser, selectCurrentUserFullName],
  (currentUser, fullName) => {
    if (!currentUser) {
      return "";
    }

    return currentUser.email?.split("@")[0];
  }
);

const selectCurrentUserAvatar = createSelector(
  [selectCurrentUser],
  (currentUser) => {
    if (
      !currentUser ||
      !currentUser.avatars ||
      !currentUser.avatars.length ||
      !currentUser.avatars[0].downloadUrl
    ) {
      return null;
    }

    return currentUser.avatars[0].downloadUrl;
  }
);

const selectSignupMessage = createSelector(
  [selectRaw],
  (auth) => auth.signupMessage
);

const selectLoadingCuitValdiation = createSelector(
  [selectRaw],
  (auth) => auth.loadingCuitValdiation
);

const selectPersonalData = createSelector(
  [selectRaw],
  (auth) => auth.personalData
);

const selectBusinessData = createSelector(
  [selectRaw],
  (auth) => auth.businessData
);

const selectForgotPasswordMessage = createSelector(
  [selectRaw],
  (auth) => auth.forgotPasswordMessage
);

const selectIsTZ = createSelector([selectRaw], (auth) => auth.relaciones.isTZ);

const selectIsCliente = createSelector(
  [selectRaw],
  (auth) => auth.relaciones.isCliente
);

const selectIsSuperAdmin = createSelector([selectCurrentUser], (auth) => {
  if (!auth) return false;
  const [{ name }] = auth.roles;
  console.log(name, auth.userType);
  return auth.userType === "SuperUser" && name === "ADMIN";
});

const selectIsAdmin = createSelector([selectRaw], (auth) => auth.roles.isAdmin);

const selectIsConsulta = createSelector(
  [selectRaw],
  (auth) => auth.roles.isConsulta
);

const selectIsTransaccional = createSelector(
  [selectRaw],
  (auth) => auth.roles.isTransaccional
);

const selectRoles = createSelector([selectCurrentUser], (auth) => auth.roles);

const selecrPrimaryRol = createSelector([selectRoles], (roles) =>
  roles[0].name.toLowerCase()
);

const selectResourcesCode = createSelector([selectCurrentUser], (user) => {
  if (!user) {
    return [];
  }
  return user.roles[0].resources.map(({ code }) => code);
});

const authSelectors = {
  selectLoadingInit,
  selectLoadingUpdateProfile,
  selectLoading,
  selectSignedIn,
  selectCurrentUserFullName,
  selectCurrentUserEmail,
  selectCurrentUser,
  selectAuthenticationUser,
  selectErrorMessage,
  selectRaw,
  selectCurrentUserNameOrEmailPrefix,
  selectCurrentUserAvatar,
  selectSignupMessage,
  selectLoadingCuitValdiation,
  selectPersonalData,
  selectBusinessData,
  selectForgotPasswordMessage,
  selectIsTZ,
  // selectIsDespachante,
  selectIsCliente,
  selectActionSend,
  selectIsAdmin,
  selectIsConsulta,
  selectIsTransaccional,
  selectCurrentUserUserType,
  selectRoles,
  selectResourcesCode,
  selecrPrimaryRol,
  selectIsSuperAdmin
};

export default authSelectors;
