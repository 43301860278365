import { fil } from "date-fns/locale";
import billingListActions from "src/modules/billing/list/billingListActions";
import checkingAccountListActions from "src/modules/checkingAccount/list/checkingAccountListActions";
import service from "../ReceiptService";
import receiptListSelectors from "./receiptListSelectors";

const prefix = "RECEIPT_LIST";

const receiptListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,

  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  FILTER_STARTED: `${prefix}_FILTER_STARTED`,
  FILTER_SUCCESS: `${prefix}_FILTER_SUCCESS`,
  FILTER_ERROR: `${prefix}_FILTER_ERROR`,

  FETCH_DETAILS_STARTED: `${prefix}_FETCH_DETAILS_STARTED`,
  FETCH_DETAILS_SUCCESS: `${prefix}_FETCH_DETAILS_SUCCESS`,
  FETCH_DETAILS_ERROR: `${prefix}_FETCH_DETAILS_ERROR`,

  PAGINATION_DETAILS_CHANGED: `${prefix}_PAGINATION_DETAILS_CHANGED`,

  SORTER_DETAILS_CHANGED: `${prefix}_SORTER_DETAILS_CHANGED`,

  RESET_ROWS: `${prefix}_RESET_ROWS`,

  CLEAN_FILTER_SUCCESS: `${prefix}_CLEAN_FILTER_SUCCESS`,

  RESET_PAGINATION: `${prefix}_RESET_PAGINATION`,

  TYPE_OF_RECEIPT_STARTED: `${prefix}_TYPE_OF_RECEIPT_STARTED`,
  TYPE_OF_RECEIPT_SUCCESS: `${prefix}_TYPE_OF_RECEIPT_SUCCESS`,
  TYPE_OF_RECEIPT_ERROR: `${prefix}_TYPE_OF_RECEIPT_ERROR`,
  SALDO_TOTAL_SUCCESS: `${prefix}_SALDO_TOTAL_SUCCESS`,
  SALDO_TOTAL_ERROR: `${prefix}_SALDO_TOTAL_ERROR`,

  doFetch: (cuit?) => async (dispatch, getState) => {
    try {
      if (!Boolean(cuit)) return;
      dispatch({
        type: receiptListActions.FETCH_STARTED,
      });
      // const filter = stockListSelectors.selectFilterCustom(getState());

      const response = await service.getReceipts(
        cuit,
        receiptListSelectors.selectOrderBy(getState()),
        receiptListSelectors.selectLimit(getState()),
        receiptListSelectors.selectOffset(getState()),
        receiptListSelectors.selectFilter(getState())
      );
      dispatch({
        type: receiptListActions.FETCH_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: receiptListActions.FETCH_ERROR,
      });
    }
  },

  doChangePagination: (pagination, rut) => async (dispatch) => {
    dispatch({
      type: receiptListActions.PAGINATION_CHANGED,
      payload: pagination,
    });
    dispatch(receiptListActions.doFetch(rut));
  },

  doChangeSort: (sorter, rutSelected) => async (dispatch) => {
    dispatch({
      type: receiptListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(receiptListActions.doFetch(rutSelected));
  },

  doChangeFilter: (filter, companySelected,centerFilter = true ) => async (dispatch) => {
    dispatch(receiptListActions.doResetPagination());
    centerFilter && dispatch(checkingAccountListActions.doChangeFilterCenter(filter, companySelected));
    centerFilter && dispatch(billingListActions.doChangeFilterCenter(filter, companySelected)); 
    dispatch({
      type: receiptListActions.FILTER_SUCCESS,
      payload: filter,
    });

    dispatch(receiptListActions.doFetch(companySelected));
  },

  doChangeFilterCenter:
    (filter, companySelected) => async (dispatch, getState) => {
      let currentFilter = receiptListSelectors.selectFilter(getState());
      let newFilter = {
        fchemiDesde: filter?.fchemiDesde || filter.fchMovDesde,
        fchemiHasta: filter?.fchemiHasta || filter.fchMovHasta,
        impDesde: filter?.totalDesde || currentFilter.impDesde,
        impHasta: filter?.totalHasta || currentFilter.impHasta,
        formul: currentFilter?.formul || undefined,
        nrofor: filter?.nrocomp,
      };
      dispatch(receiptListActions.doChangeFilter(newFilter, companySelected));
    },

  doFetchDetails: (rut?, nro_recibo?) => async (dispatch, getState) => {
    dispatch({
      type: receiptListActions.RESET_ROWS,
    });
    try {
      dispatch({
        type: receiptListActions.FETCH_DETAILS_STARTED,
      });
      const response = await service.getReceiptsDetails(rut, nro_recibo);
      dispatch({
        type: receiptListActions.FETCH_DETAILS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: receiptListActions.FETCH_DETAILS_ERROR,
      });
    }
  },

  resetRows: () => async (dispatch) => {
    dispatch({
      type: receiptListActions.RESET_ROWS,
    });
  },

  doChangePaginationDetails:
    (pagination, rut, nro_recibo) => async (dispatch) => {
      dispatch({
        type: receiptListActions.PAGINATION_DETAILS_CHANGED,
        payload: pagination,
      });
      dispatch(receiptListActions.doFetchDetails(rut, nro_recibo));
    },

  doChangeSortDetails:
    (sorter, rutSelected, nro_recibo) => async (dispatch) => {
      dispatch({
        type: receiptListActions.SORTER_DETAILS_CHANGED,
        payload: sorter,
      });

      dispatch(receiptListActions.doFetchDetails(rutSelected, nro_recibo));
    },

  cleanFilter: (companySelected) => async (dispatch) => {
    dispatch({
      type: receiptListActions.CLEAN_FILTER_SUCCESS,
    });

    dispatch(receiptListActions.doFetch(companySelected));
  },
  doResetPagination: () => async (dispatch) => {
    dispatch({
      type: receiptListActions.RESET_PAGINATION,
    });
  },
  getTypeOfReceipts: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: receiptListActions.TYPE_OF_RECEIPT_STARTED,
      });

      const response = await service.getTypeOfReceipts();
      dispatch({
        type: receiptListActions.TYPE_OF_RECEIPT_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: receiptListActions.TYPE_OF_RECEIPT_ERROR,
      });
    }
  },
  getSaldoTotal: (cuit) => async (dispatch, getState) => {
    try {
      dispatch({
        type: receiptListActions.TYPE_OF_RECEIPT_STARTED,
      });

      const response = await service.getSaldoTotal(cuit);
      dispatch({
        type: receiptListActions.SALDO_TOTAL_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: receiptListActions.SALDO_TOTAL_ERROR,
        payload: { message: "Cliente no válido de consulta o incorrecto." },
      });
    }
  },
};

export default receiptListActions;
