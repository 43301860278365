import authAxios from 'src/modules/shared/axios/authAxios';
import authAxios2 from 'src/modules/shared/axios/authAxios2';
import Message from 'src/view/shared/message';
import { TokenAzureAd } from '../auth/TokenAzureAd';

export default class CompanyService {
  static async find(cuit, clientCompanyId?) {

    const response = await authAxios2.get(`/portal/stakeholder/consultaCuit?cuit=${cuit}&clientCompanyId=${clientCompanyId}`);

    if (response.data.code === "MS-CO-OP003-ERR-001") {
      Message.error(`${response.data.message}`)
    }
    return response.data;
  }

  static async createRelation(stakeholderCuit, companyId) {
    const response = await authAxios2.post(
      `/portal/stakeholder/companyHasStakeholder?stakeholderCuit=${stakeholderCuit}&clientCompanyId=${companyId}`,
    );
    return response.data;
  }

  static async editUserProvider(stakeholderId, companyName) {

    const response = await authAxios2.put(`/portal/stakeholder/${stakeholderId}/companyName?companyName=${companyName}`);
    return response.data;
  }

  static async createUserProvider(cuit, companyName, clientCompanyId, data?) {
    const token = await TokenAzureAd.getAccessToken();
    const response = await authAxios.post('/portal/stakeholder/admin', { ...data, token }, { params: { cuit, companyName, clientCompanyId } });
    if (response.data.code === "MS-CO-OP003-OK-003") {
      Message.success(`El usuario ${data.email} se creó exitosamente para el proveedor ${companyName} y se estableció la relación.`)
    }
    if (response.data.code === "MSCO-STK-003-404") {
      Message.error(`${response.data.message}`)
    }

    return response.data;
  }

  //tabla despachantes /providers
  static async fetchUsers(orderBy?, limit?, offset?, filter?) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      clientCompanyId: filter?.clientCompanyId ? filter?.clientCompanyId : undefined,
      stakeholderId: filter?.stakeholderId ? filter?.stakeholderId : undefined,

    };
    const response = await authAxios2.get(`/portal/clientCompany-has-stakeHolder`, { params });

    return response.data;
  }

  static async getStakeholderUsers(id) {

    const response = await authAxios2.get(`/portal/stakeholder/${id}/users`);

    return response.data;
  }


  static async edit(data) {
    const body = {
      ...data,
    };

    const response = await authAxios.put(
      `/user/`,
      body,
    );

    return response.data;
  }

  // static async destroyLogic(id: String,) {
  // 	const response = await authAxios2.delete(`/inventarios/user/${id}`);
  // 	return response.data;
  //   }


  //   static async activeLogic(id: String) {
  // 	const response = await authAxios2.patch(`/inventarios/user/${id}/enabled`);
  // 	return response.data;
  //   } 

  static async deleteStakeholder(stakeholderId, clientCompanyId) {
    const response = await authAxios2.delete(`/portal/stakeholder/${stakeholderId}/companyHasStakeholder?clientCompanyId=${clientCompanyId}`);
    return response.data; //usuario eliminado
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(
      `/user`,
      body,
    );

    return response.data;
  }
  static async fetchUserAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(
      `/user/autocomplete`,
      {
        params,
      },
    );
    return response.data;
  }

  static async editProvider(id, data) {
    const response = await authAxios2.put(`/portal/stakeholder/admin/${id}`, data);
    return response.data;
  }

  static async deleteProvider(id) {
    const response = await authAxios2.delete(`/portal/stakeholder/admin/${id}`);
    return response.data;
  }

  static async activeProvider(id) {
    const response = await authAxios2.patch(`portal/stakeholder/admin/${id}/enabled`);
    return response.data;
    
  }
}
